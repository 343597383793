<template>
  <v-app
    class="app"
    :style="{ background: $vuetify.theme.themes[theme].background }"
  >
    <layout-loading v-if="loading" :overlay="loadingOverlay"></layout-loading>
    <component v-else :is="resolveLayout">
      <router-view></router-view>
    </component>
  </v-app>
</template>

<script>
import LayoutBlank from "@/layouts/Blank.vue";
import LayoutContent from "@/layouts/Content.vue";
// import LayoutLoading from "@/layouts/Loading.vue"
import LayoutLoading from "@/components/_system/helpers/Loading.vue";
// import { IN_BROWSER } from "@/util/globals";

export default {
  name: "app",
  components: {
    LayoutBlank,
    LayoutContent,
    LayoutLoading,
  },
  data() {
    return {
      loadingOverlay: true,
      darkModeEnabled: matchMedia("(prefers-color-scheme: dark)").matches,
    };
  },
  computed: {
    loading() {
      return this.$store.state.user.userAuthCheckInProgress;
    },
    resolveLayout() {
      // Handles initial route
      if (this.$route.name === null) return null;

      if (this.$route.meta.layout === "blank") return "layout-blank";

      return "layout-content";
    },
    appearanceSetting() {
      return this.$store.state.user.user.settings.appearance
        ? this.$store.state.user.user.settings.appearance
        : null;
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
  watch: {
    $route: "setLanguageOfVuetify",
  },
  mounted() {
    this.media = matchMedia("(prefers-color-scheme: dark)");
    this.$nextTick(() => {
      this.media.addEventListener("change", this.setDarkMode);
    });
    if (this.darkModeEnabled) {
      this.$vuetify.theme.dark = true;
    }
  },
  created() {
    this.setLanguageOfVuetify();
    // this.$vuetify.theme.dark = window.matchMedia(
    //   "(prefers-color-scheme: dark)"
    // ).matches;
    // const matchMedia = this.mql = this.getMatchMedia()
    //   if (!matchMedia) return
    // if (this.appearanceSetting === 'system') {
    //   this.dark = matchMedia.matches
    // }
    // matchMedia.onchange = ({ matches }) => {
    //   if (this.system) {
    //     this.dark = matches
    //   }
    // }

    // if (this.appearanceSetting) {
    //   this.setTheme(this.appearanceSetting);
    // } else {
    //   this.setTheme("auto");
    // }
  },
  methods: {
    setLanguageOfVuetify() {
      this.$vuetify.lang.current = this.$i18n.locale;
    },
    setDarkMode({ matches }) {
      this.$vuetify.theme.dark = matches;
    },
    // getMatchMedia() {
    //   return IN_BROWSER && window.matchMedia
    //     ? window.matchMedia("(prefers-color-scheme: dark)")
    //     : false;
    // },
    // setTheme(state) {
    //   if (state === "dark") {
    //     this.$vuetify.theme.dark = true;
    //   } else if (state === "auto") {
    //     const systemPrefersDark = this.getMatchMedia();
    //     console.log(systemPrefersDark);
    //     if (systemPrefersDark) {
    //       this.$vuetify.theme.dark = true;
    //     } else {
    //       this.$vuetify.theme.dark = false;
    //     }
    //   } else {
    //     // light
    //     this.$vuetify.theme.dark = false;
    //   }
    //   return state;
    // },
  },
  beforeDestroy() {
    this.media.removeEventListener("change", this.setDarkMode);
  },
};
</script>
<style>
/* #content {
   height: 100vh !important; 
}
#footer {
  bottom: 0px !important;
} */

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#main-wrapper {
  box-sizing: border-box;
  min-height: 100%;
  padding: 0 0 100px;
  position: relative;
}

#footer {
  bottom: 0;
  height: 100px;
  left: 0;
  position: absolute;
  width: 100%;
}

/* roboto-100 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 100;
  src: local(""),
    url("../public/fonts/roboto-v29-latin-100.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../public/fonts/roboto-v29-latin-100.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-300 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: local(""),
    url("../public/fonts/roboto-v29-latin-300.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../public/fonts/roboto-v29-latin-300.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-regular - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local(""),
    url("../public/fonts/roboto-v29-latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../public/fonts/roboto-v29-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-500 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: local(""),
    url("../public/fonts/roboto-v29-latin-500.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../public/fonts/roboto-v29-latin-500.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-700 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: local(""),
    url("../public/fonts/roboto-v29-latin-700.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../public/fonts/roboto-v29-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-900 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  src: local(""),
    url("../public/fonts/roboto-v29-latin-900.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../public/fonts/roboto-v29-latin-900.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
</style>
