// import * as fb from '../firebase'
import * as fb from "@/firebase";
import router from "@/router/index";
import {
  SIGN_IN,
  SIGN_OUT,
  SIGN_UP,
  GET_USER_PROFILE,
  GET_MEMBERSHIPS,
  USER_SETUP,
  UPDATE_USER_DETAILS,
  UPDATE_PRIVACY_SETTINGS,
  UPDATE_USER_LANGUAGE,
  UPDATE_NOTIFICATION_SETTINGS,
} from "../action-types";
import {
  SET_USER,
  SET_USER_IS_AUTHENTICATED,
  SET_MEMBERSHIPS,
  SET_USER_AUTH_CHECK_STATUS,
} from "../mutation-types";

export default {
  namespaced: true,
  state: {
    userAuthCheckInProgress: true,
    userIsAuthenticated: false,
    user: {
      settings: {
        appearance: "auto",
        accessability: { useColorBlindMode: false },
      },
    },
    memberships: [],
  },

  mutations: {
    [SET_USER_AUTH_CHECK_STATUS](state, payload) {
      state.userAuthCheckInProgress = payload;
    },

    [SET_USER_IS_AUTHENTICATED](state, payload) {
      state.userIsAuthenticated = payload;
    },

    [SET_USER](state, payload) {
      state.user = payload;
    },

    [SET_MEMBERSHIPS](state, payload) {
      state.memberships = payload;
    },
  },

  actions: {
    [SIGN_IN]({ dispatch }, payload) {
      // Sign user in
      return fb.auth
        .signInWithEmailAndPassword(payload.email, payload.password)
        .then(() => {
          console.info("Welcome:", fb.auth.currentUser.uid);
          return dispatch(GET_USER_PROFILE, {
            uid: fb.auth.currentUser.uid,
          });
        });
    },

    [SIGN_UP]({ dispatch }, payload) {
      // sign user up
      return fb.auth
        .createUserWithEmailAndPassword(payload.email, payload.password)
        .then(function (result) {
          return result.user.sendEmailVerification();
        })
        .then(function () {
          const emailIsVerified = fb.auth.currentUser.emailVerified;
          const setupIsDone = false; // Get auth customClaim

          if (!emailIsVerified) {
            router.push({ name: "setup-verify-email" });
          } else if (!setupIsDone) {
            router.push({ name: "setup-onboarding" });
          } else {
            router.push({ name: "organization-list" });
          }
        })
        .then(function () {
          // fetch user and set in state
          dispatch(GET_USER_PROFILE, {
            uid: fb.auth.currentUser.uid,
          });
        });
    },

    async [GET_USER_PROFILE]({ commit }, payload) {
      // Get profile of user from Firestore
      const userProfile = await fb.usersCollection.doc(payload.uid).get();
      const data = userProfile.data();
      // Set userProfile in state
      commit(SET_USER, data);

      let searchParams = new URLSearchParams(window.location.search);
      let allowRedirect = payload.allowRedirect ? payload.allowRedirect : false;
      if (allowRedirect) {
        if (data.meta.awaitsSetup) {
          router.push({ name: "setup-onboarding" });
        } else if (searchParams.has("redirect")) {
          router.push({ path: `${searchParams.get("redirect")}` });
        } else if (data.settings.preferedOrg.id) {
          router.push({
            name: "my-organization-dashboard",
            params: { organizationId: data.settings.preferedOrg.id },
          });
        } else {
          router.push({ name: "organization-list" });
        }
      }
    },

    [GET_MEMBERSHIPS]({ commit, state }) {
      // commit(SET_LOADING, true);
      const data = [];
      var preferedOrgID = state.user.settings.preferedOrg.id;
      fb.db
        .collectionGroup("membershipsPrivate")
        .where("user.uid", "==", fb.auth.currentUser.uid)
        .where("access.status", "in", ["activated", "deactivated"])
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const object = doc.data();
            if (
              doc.data().access.status === "deactivated" ||
              doc.data().access.disabled
            ) {
              object.access.isDisabled = true;
            } else {
              object.access.isDisabled = false;
            }
            if (preferedOrgID && preferedOrgID == doc.data().organization.id) {
              object.organization.isPrefered = true;
            } else {
              object.organization.isPrefered = false;
            }
            data.push(object);
          });
          commit(SET_MEMBERSHIPS, data);
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
      // commit(SET_LOADING, false);
    },

    async [SIGN_OUT]({ commit }) {
      // log user out
      await fb.auth.signOut();
      console.log("user signed out");
      // clear user data from state
      commit(SET_USER_IS_AUTHENTICATED, false);
      commit(SET_USER, {
        settings: {
          appearance: "auto",
          accessability: { useColorBlindMode: false },
        },
      });
      commit(SET_USER_AUTH_CHECK_STATUS, false);
      // redirect to login view
      router.push({ name: "auth-login" });
    },

    [USER_SETUP](payload) {
      var userRef = fb.usersCollection.doc(fb.auth.currentUser.uid);
      userRef
        .update({
          firstName: payload.firstName,
          lastName: payload.lastName,
          // photoURL: payload.photoURL,
          "meta.setupDone": payload.meta.setupDone,
          "settings.language": payload.settings.language,
          "meta.accountType": payload.meta.accountType,
          "privacy.web.googleAnalytics.consentTimestamp":
            payload.privacy.web.googleAnalytics.consentTimestamp,
          "privacy.web.googleAnalytics.usage":
            payload.privacy.web.googleAnalytics.usage,
          "privacy.web.performanceMonitoring.consentTimestamp":
            payload.privacy.web.performanceMonitoring.consentTimestamp,
          "privacy.web.performanceMonitoring.usage":
            payload.privacy.web.performanceMonitoring.usage,
        })
        .then(function () {
          console.log("Document successfully updated!");
        })
        .then(() => {
          return fb.auth.currentUser.updateProfile({
            displayName: payload.firstName + " " + payload.lastName,
          });
        })
        .catch(function (error) {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
        });
    },

    [UPDATE_PRIVACY_SETTINGS]({ dispatch }, payload) {
      var userRef = fb.usersCollection.doc(fb.auth.currentUser.uid);
      userRef
        .update({
          "privacy.web.googleAnalytics.consentTimestamp":
            payload.privacy.web.googleAnalytics.consentTimestamp,
          "privacy.web.googleAnalytics.usage":
            payload.privacy.web.googleAnalytics.usage,
          "privacy.web.performanceMonitoring.consentTimestamp":
            payload.privacy.web.performanceMonitoring.consentTimestamp,
          "privacy.web.performanceMonitoring.usage":
            payload.privacy.web.performanceMonitoring.usage,
        })
        .then(function () {
          console.log("Document successfully updated!");
          dispatch(GET_USER_PROFILE, {
            uid: fb.auth.currentUser.uid,
            allowRedirect: false,
          });
        })
        .catch(function (error) {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
        });
    },

    [UPDATE_USER_LANGUAGE](payload) {
      var userRef = fb.usersCollection.doc(fb.auth.currentUser.uid);
      userRef
        .update({
          "settings.language": payload,
        })
        .then(function () {
          console.log("Document successfully updated!");
          // dispatch(GET_USER_PROFILE, {
          //   uid: auth.currentUser.uid,
          //   allowRedirect: false,
          // });
          // Reload page with updated language
        })
        .catch(function (error) {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
        });
    },

    [UPDATE_NOTIFICATION_SETTINGS]({ dispatch }, payload) {
      console.log(payload);
      var userRef = fb.usersCollection.doc(fb.auth.currentUser.uid);
      userRef
        .update({
          "settings.notifications.email.activeTriggers":
            payload.activeTriggersEmail,
        })
        .then(() => {
          dispatch(GET_USER_PROFILE, {
            uid: fb.auth.currentUser.uid,
            allowRedirect: false,
          });
        })
        .catch(function (error) {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
        });
    },

    [UPDATE_USER_DETAILS]({ dispatch }, payload) {
      var userRef = fb.usersCollection.doc(fb.auth.currentUser.uid);
      userRef
        .update({
          firstName: payload.firstName,
          lastName: payload.lastName,
        })
        .then(() => {
          console.log("Document successfully updated!");
        })
        .then(() => {
          return fb.auth.currentUser.updateProfile({
            displayName: payload.firstName + " " + payload.lastName,
          });
        })
        .then(() => {
          console.log(fb.auth.currentUser);
          dispatch(GET_USER_PROFILE, {
            uid: fb.auth.currentUser.uid,
            allowRedirect: false,
          });
        })
        .catch(function (error) {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
        });
    },
  },
  getters: {
    checkIfSupport: (state) => {
      const supportStatus = state.user.meta.isSupport || false;
      if (supportStatus) {
        return true;
      } else {
        return false;
      }
    },
    // return uid
    getUid: () => {
      return fb.auth.currentUser.uid;
    },
  },
};
