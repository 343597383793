import i18n from "../plugins/i18n";
import { selfService } from "./module-routes/routes-self-service";
import { myOrganization } from "./module-routes/routes-my-organization";
import { scheduling } from "./module-routes/routes-scheduling";
import { availability } from "./module-routes/routes-availability";
import { dutyManagement } from "./module-routes/routes-duty-management";
import { memberManagement } from "./module-routes/routes-member-management";
import { stats } from "./module-routes/routes-stats";
import { accounting } from "./module-routes/routes-accounting";
import { finance } from "./module-routes/routes-finance";
import { admin } from "./module-routes/routes-admin";

export const routes = [
  {
    path: "/",
    redirect: `/${i18n.locale}/login`,
  },
  {
    path: "/:locale",
    name: "locale-base",
    component: {
      render: (h) => h("router-view"),
    },
    meta: {
      requiresAuth: false,
      requiresPermission: false,
      permissionID: "",
    },
    children: [
      {
        path: "/",
        redirect: `/${i18n.locale}/login`,
        meta: {
          requiresAuth: false,
          requiresPermission: false,
          permissionID: "",
        },
      },
      // Auth
      {
        path: "login",
        name: "auth-login",
        component: () =>
          import(/* webpackChunkName: "auth" */ "../views/auth/AuthLogin.vue"),
        meta: { layout: "blank" },
      },
      {
        path: "register",
        name: "auth-register",
        component: () =>
          import(
            /* webpackChunkName: "auth" */ "../views/auth/AuthRegister.vue"
          ),
        meta: { layout: "blank" },
      },

      {
        path: "maintenance",
        name: "maintenance",
        component: () =>
          import(
            /* webpackChunkName: "errors" */ "../views/_system/MaintenanceView.vue"
          ),
        meta: { requiresAuth: false, requiresPermission: false },
      },

      // Willkommen und Erste Schritte
      {
        path: "onboarding",
        name: "setup-onboarding",
        component: () =>
          import(
            /* webpackChunkName: "setup" */ "../views/setup/SetupOnboarding.vue"
          ),
        meta: {
          layout: "blank",
          requiresAuth: true,
          requiresPermission: false,
        },
      },
      {
        path: "verify-email",
        name: "setup-verify-email",
        component: () =>
          import(
            /* webpackChunkName: "setup" */ "../views/setup/SetupVerifyEmail.vue"
          ),
        meta: {
          layout: "blank",
          requiresAuth: true,
          requiresPermission: false,
        },
      },
      {
        path: "error",
        name: "error",
        component: () =>
          import(
            /* webpackChunkName: "errors" */ "../views/_system/errors/error.vue"
          ),
        meta: {
          layout: "content-without-drawer",
          requiresAuth: true,
          requiresPermission: false,
        },
      },
      {
        path: "notifications",
        name: "notification-center",
        component: () =>
          import(
            /* webpackChunkName: "errors" */ "../views/notification-center/NotificationCenter.vue"
          ),
        meta: {
          layout: "content-without-drawer",
          requiresAuth: true,
          requiresPermission: false,
        },
      },
      // settings
      {
        path: "settings",
        name: "settings",
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "../views/settings/Settings.vue"
          ),
        meta: {
          layout: "content-without-drawer",
          requiresAuth: true,
          requiresPermission: false,
        },
        children: [
          {
            path: "profile",
            name: "settings-profile",
            component: () =>
              import(
                /* webpackChunkName: "settings" */ "../views/settings/SettingsProfile.vue"
              ),
            meta: {
              layout: "content-without-drawer",
              requiresAuth: true,
              requiresPermission: false,
            },
          },
          {
            path: "organizations",
            name: "settings-organizations",
            component: () =>
              import(
                /* webpackChunkName: "settings" */ "../views/settings/SettingsOrganizations.vue"
              ),
            meta: {
              layout: "content-without-drawer",
              requiresAuth: true,
              requiresPermission: false,
            },
          },
          {
            path: "appearance",
            name: "settings-appearance",
            component: () =>
              import(
                /* webpackChunkName: "settings" */ "../views/settings/SettingsAppearance.vue"
              ),
            meta: {
              layout: "content-without-drawer",
              requiresAuth: true,
              requiresPermission: false,
            },
          },
          {
            path: "accessability",
            name: "settings-accessability",
            component: () =>
              import(
                /* webpackChunkName: "settings" */ "../views/settings/SettingsAccessability.vue"
              ),
            meta: {
              layout: "content-without-drawer",
              requiresAuth: true,
              requiresPermission: false,
            },
          },
          // {
          //   path: "contact",
          //   name: "settings-contact",
          //   component: () =>
          //     import(
          //       /* webpackChunkName: "settings" */ "../views/settings/SettingsContact.vue"
          //     ),
          //   meta: { layout: "content-without-drawer",requiresAuth: true, requiresPermission: false },
          // },
          {
            path: "notifications",
            name: "settings-notifications",
            component: () =>
              import(
                /* webpackChunkName: "settings" */ "../views/settings/SettingsNotifications.vue"
              ),
            meta: {
              layout: "content-without-drawer",
              requiresAuth: true,
              requiresPermission: false,
            },
          },
          {
            path: "privacy",
            name: "settings-privacy",
            component: () =>
              import(
                /* webpackChunkName: "settings" */ "../views/settings/SettingsPrivacy.vue"
              ),
            meta: {
              layout: "content-without-drawer",
              requiresAuth: true,
              requiresPermission: false,
            },
          },
          {
            path: "emails",
            name: "settings-emails",
            component: () =>
              import(
                /* webpackChunkName: "settings" */ "../views/settings/SettingsEmails.vue"
              ),
            meta: {
              layout: "content-without-drawer",
              requiresAuth: true,
              requiresPermission: false,
            },
          },
          {
            path: "security",
            name: "settings-security",
            component: () =>
              import(
                /* webpackChunkName: "settings" */ "../views/settings/SettingsSecurity.vue"
              ),
            meta: {
              layout: "content-without-drawer",
              requiresAuth: true,
              requiresPermission: false,
            },
          },
        ],
      },

      // Beitritt zu Organisation
      {
        path: "i/:organizationId/:inviteId/:inviteKey",
        name: "organization-join",
        component: () =>
          import(
            /* webpackChunkName: "org-extra" */ "../views/organization/OrganizationJoin.vue"
          ),
        meta: {
          layout: "content-without-drawer",
          requiresAuth: true,
          requiresPermission: false,
        },
      },

      // Organisation
      {
        path: "organizations",
        name: "organization-list",
        component: () =>
          import(
            /* webpackChunkName: "organization" */ "../views/organization/OrganizationList.vue"
          ),
        meta: {
          layout: "content-without-drawer",
          requiresAuth: true,
          requiresPermission: false,
        },
      },
      {
        path: "organizations/new",
        name: "organization-new",
        component: () =>
          import(
            /* webpackChunkName: "org-extra" */ "../views/organization/OrganizationNew.vue"
          ),
        meta: {
          layout: "content-without-drawer",
          requiresAuth: true,
          requiresPermission: false,
        },
      },
      {
        path: "organizations/:organizationId/",
        redirect: "organizations/:organizationId/my-organization/dashboard",
        name: "organization-detail",
        component: () =>
          import(
            /* webpackChunkName: "organization" */ "../views/organization/OrganizationParent.vue"
          ),
        meta: {
          requiresAuth: true,
          requiresPermission: true,
          permissionID: "",
        },
        children: [
          {
            path: "privacy-policy",
            name: "organization-privacy-policy",
            component: () =>
              import(
                /* webpackChunkName: "organization" */ "../views/organization/OrganizationPrivacyPolicy.vue"
              ),
            meta: {
              requiresAuth: true,
              requiresPermission: true,
              permissionID: "",
            },
          },

          // self-service
          selfService,

          //my-organization
          myOrganization,

          // scheduling
          scheduling,

          // duty-management
          dutyManagement,

          // availability
          availability,

          // member-management
          memberManagement,

          // accounting
          accounting,

          // finance
          finance,

          // stats
          stats,

          // admin
          admin,

          // support
          {
            path: "support",
            name: "support",
            component: () =>
              import(
                /* webpackChunkName: "support" */ "../views/support/Support.vue"
              ),
            meta: {
              requiresAuth: true,
              requiresPermission: true,
              permissionID: "",
            },
          },
        ],
      },

      // Catch all
      {
        path: "*",
        name: "not-found",
        component: () =>
          import(
            /* webpackChunkName: "errors" */ "../views/_system/errors/NotFound.vue"
          ),
        meta: {
          layout: "content-without-drawer",
          requiresAuth: true,
          requiresPermission: false,
        },
      },
    ],
  },
];
