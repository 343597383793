import {
  MY_ORGANIZATION_DASHBOARD_read,
  MY_ORGANIZATION_UNITS_read,
  MY_ORGANIZATION_NEWS_read,
  MY_ORGANIZATION_NEWS_create,
  MY_ORGANIZATION_NEWS_update,
  MY_ORGANIZATION_SURVEYS_read,
  MY_ORGANIZATION_SURVEYS_create,
  MY_ORGANIZATION_SURVEYS_update,
  MY_ORGANIZATION_ASSIGNMENTS_read,
  MY_ORGANIZATION_ORGANIZATIONAL_CHART_read,
  MY_ORGANIZATION_KNOWLEDGE_BASE_read,
} from "@/data/permission-types.js";

export const myOrganization = {
  path: "my-organization",
  name: "my-organization",
  component: () =>
    import(
      /* webpackChunkName: "myOrganization" */ "../../views/my-organization/MyOrganizationIndex.vue"
    ),
  meta: {
    requiresAuth: true,
    requiresPermission: true,
  },
  children: [
    // my-organization > dashboard
    {
      path: "dashboard",
      name: "my-organization-dashboard",
      component: () =>
        import(
          /* webpackChunkName: "myOrganization-dashboard" */ "../../views/my-organization/MyOrganizationDashboard.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${MY_ORGANIZATION_DASHBOARD_read}`,
      },
    },
    // my-organization > units
    {
      path: "units",
      name: "my-organization-units",
      component: () =>
        import(
          /* webpackChunkName: "myOrganization-units" */ "../../views/my-organization/MyOrganizationUnits.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${MY_ORGANIZATION_UNITS_read}`,
      },
    },
    // my-organization > news
    {
      path: "news",
      name: "my-organization-news",
      component: () =>
        import(
          /* webpackChunkName: "myOrganization-news" */ "../../views/my-organization/MyOrganizationNews.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${MY_ORGANIZATION_NEWS_read}`,
      },
    },
    {
      path: "news/new",
      name: "my-organization-news-new",
      component: () =>
        import(
          /* webpackChunkName: "myOrganization-news" */ "../../views/my-organization/news/NewsNew.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${MY_ORGANIZATION_NEWS_create}`,
      },
    },
    {
      path: "news/inbound-email",
      name: "my-organization-news-inbound-email",
      component: () =>
        import(
          /* webpackChunkName: "myOrganization-news" */ "../../views/my-organization/news/NewsInboundEmail.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${MY_ORGANIZATION_NEWS_create}`,
      },
    },
    {
      path: "news/:itemId",
      name: "my-organization-news-details",
      component: () =>
        import(
          /* webpackChunkName: "myOrganization-news" */ "../../views/my-organization/news/NewsDetails.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${MY_ORGANIZATION_NEWS_read}`,
      },
    },
    {
      path: "news/:itemId/edit",
      name: "my-organization-news-edit",
      component: () =>
        import(
          /* webpackChunkName: "myOrganization-news" */ "../../views/my-organization/news/NewsEdit.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${MY_ORGANIZATION_NEWS_update}`,
      },
    },
    // my-organization > surveys
    {
      path: "surveys",
      name: "my-organization-surveys",
      component: () =>
        import(
          /* webpackChunkName: "myOrganization-surveys" */ "../../views/my-organization/MyOrganizationSurveys.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${MY_ORGANIZATION_SURVEYS_read}`,
      },
    },
    {
      path: "surveys/new",
      name: "my-organization-surveys-new",
      component: () =>
        import(
          /* webpackChunkName: "myOrganization-surveys" */ "../../views/my-organization/surveys/SurveyNew.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${MY_ORGANIZATION_SURVEYS_create}`,
      },
    },
    {
      path: "surveys/:itemId",
      name: "my-organization-surveys-details",
      component: () =>
        import(
          /* webpackChunkName: "myOrganization-surveys" */ "../../views/my-organization/surveys/SurveyDetails.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${MY_ORGANIZATION_SURVEYS_read}`,
      },
    },
    {
      path: "surveys/:itemId/edit",
      name: "my-organization-surveys-edit",
      component: () =>
        import(
          /* webpackChunkName: "myOrganization-surveys" */ "../../views/my-organization/surveys/SurveyEdit.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${MY_ORGANIZATION_SURVEYS_update}`,
      },
    },
    // my-organization > guardbook
    {
      path: "guardbook",
      name: "my-organization-guardbook",
      component: () =>
        import(
          /* webpackChunkName: "myOrganization-guardbook" */ "../../views/my-organization/MyOrganizationGuardbook.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: "meine_organisation.wachbuch.view_Page",
      },
    },
    {
      path: "guardbook/entries/new",
      name: "my-organization-guardbook-entries-new",
      component: () =>
        import(
          /* webpackChunkName: "myOrganization-guardbook" */ "../../views/my-organization/guardbook/EntryNew.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: "meine_organisation.wachbuch.view_Page",
      },
    },
    {
      path: "guardbook/categories/new",
      name: "my-organization-guardbook-categories-new",
      component: () =>
        import(
          /* webpackChunkName: "myOrganization-guardbook" */ "../../views/my-organization/guardbook/CategoryNew.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: "meine_organisation.wachbuch.view_Page",
      },
    },
    {
      path: "guardbook/category/:itemId",
      name: "my-organization-guardbook-categories-details",
      component: () =>
        import(
          /* webpackChunkName: "myOrganization-guardbook" */ "../../views/my-organization/guardbook/CategoryDetails.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: "meine_organisation.wachbuch.view_Page",
      },
    },
    // my-organization > knowledge base
    {
      path: "knowledge-base",
      name: "my-organization-knowledge-base",
      component: () =>
        import(
          /* webpackChunkName: "myOrganization-knowledge-base" */ "../../views/my-organization/MyOrganizationKnowledgeBase.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${MY_ORGANIZATION_KNOWLEDGE_BASE_read}`,
      },
    },
    // my-organization > organization-chart
    {
      path: "organization-chart",
      name: "my-organization-organization-chart",
      component: () =>
        import(
          /* webpackChunkName: "myOrganization-organization-chart" */ "../../views/my-organization/MyOrganizationOrganizationChart.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${MY_ORGANIZATION_ORGANIZATIONAL_CHART_read}`,
      },
    },
    // my-organization > assignments
    {
      path: "assignments",
      name: "my-organization-assignments",
      component: () =>
        import(
          /* webpackChunkName: "myOrganization-assignments" */ "../../views/my-organization/MyOrganizationAssignments.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${MY_ORGANIZATION_ASSIGNMENTS_read}`,
      },
    },
    // my-organization > config
    {
      path: "config",
      name: "my-organization-config",
      component: () =>
        import(
          /* webpackChunkName: "myOrganization-config" */ "../../views/my-organization/MyOrganizationConfig.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: "meine_organisation.konfiguration.view_Page",
      },
      // children: [
      // {
      //   path: "general",
      //   name: "my-organization-config-tab-general",
      // component: () => import( /* webpackChunkName: "myOrganization-config" */ "../../views/my-organization/config/ConfigTabGeneral.vue"),
      // },
      // {
      //   path: "dashboard",
      //   name: "my-organization-config-tab-dashboard",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "myOrganization-config" */ "../../views/my-organization/config/ConfigTabDashboard.vue"
      //     ),
      // },
      // ],
    },
  ],
};
