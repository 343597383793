<template>
  <div>
    <v-list-item>
      <v-list-item-content style="margin-right: 20px !important">
        <v-list-item-title class="font-weight-medium">
          Testversion
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ remainingDaysOfTrial }}
          {{ daysText(remainingDaysOfTrial) }} verbleibend</v-list-item-subtitle
        >
      </v-list-item-content>
      <!-- <v-list-item-action>
        <v-btn depressed color="success">Aktivieren</v-btn>
      </v-list-item-action> -->
    </v-list-item>
    <v-list-item class="pt-n3 mt-n6">
      <v-progress-linear
        :value="remainingDaysOfTrialInPercent"
        height="5"
        color="success"
        rounded
      ></v-progress-linear>
    </v-list-item>
  </div>
</template>

<script>
export default {
  name: "trial-info-card",
  props: {},
  data() {
    return {};
  },
  computed: {
    organization() {
      if (this.routeIsOrg == null || false) {
        return null;
      } else {
        return this.$store.state.organization.activeOrganization;
      }
    },
    remainingDaysOfTrial() {
      const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
      const now = new Date();
      const endDate =
        this.$store.state.organization.activeOrganization.billing.trial.endDate.toDate();
      const differenceInDays = Math.round(Math.abs((now - endDate) / oneDay));

      return differenceInDays;
    },
    remainingDaysOfTrialInPercent() {
      const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
      const createdAt =
        this.$store.state.organization.activeOrganization.meta.createdAt.toDate();
      const endOfTrial =
        this.$store.state.organization.activeOrganization.billing.trial.endDate.toDate();
      const trialDurationInDays = Math.round(
        Math.abs((createdAt - endOfTrial) / oneDay)
      );

      const daysPassed = trialDurationInDays - this.remainingDaysOfTrial;
      return (daysPassed / trialDurationInDays) * 100;
    },
  },
  methods: {
    daysText(count) {
      if (count === 1) {
        return "Tag";
      } else {
        return "Tage";
      }
    },
  },
};
</script>
