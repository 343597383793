import { SELF_SERVICE_read } from "@/data/permission-types.js";

export const selfService = {
  path: "self-service",
  name: "self-service",
  component: () =>
    import(
      /* webpackChunkName: "self-service" */ "../../views/self-service/SelfServiceIndex.vue"
    ),
  meta: {
    requiresAuth: true,
    requiresPermission: true,
  },
  children: [
    {
      path: "portal",
      name: "self-service-portal",
      component: () =>
        import(
          /* webpackChunkName: "self-service" */ "../../views/self-service/SelfServicePortal.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${SELF_SERVICE_read}`,
      },
    },
  ],
};
