import * as fb from "@/firebase";
import router from "@/router/index";

import {
  // RESET_MY_ORGANIZATION,
  GET_SURVEYS,
  CREATE_SURVEY,
  UPDATE_SURVEY,
  DELETE_SURVEY,
  GET_NEWS,
  CREATE_NEWS,
  UPDATE_NEWS,
  DELETE_NEWS,
  GET_NEWS_INBOUND_EMAILS,
  GET_GUARDBOOK_ENTRIES,
  CREATE_GUARDBOOK_ENTRY,
  UPDATE_GUARDBOOK_ENTRY,
  DELETE_GUARDBOOK_ENTRY,
  GET_GUARDBOOK_CATEGORIES,
  CREATE_GUARDBOOK_CATEGORY,
  UPDATE_GUARDBOOK_CATEGORY,
  DELETE_GUARDBOOK_CATEGORY,
} from "../action-types";
import {
  SET_LOADING,
  SET_NEWS,
  SET_NEWS_INBOUND_EMAILS,
  SET_SURVEYS,
  SET_GUARDBOOK_ENTRIES,
  SET_GUARDBOOK_CATEGORIES,
} from "../mutation-types";

export default {
  namespaced: true,

  state: {
    news: [],
    newsInboundEmails: [],
    surveys: [],
    // Guardbook
    guardbook: {
      entries: [],
      categories: [],
    },
  },

  mutations: {
    [SET_LOADING](state, payload) {
      state.loading = payload;
    },
    // News
    [SET_NEWS](state, payload) {
      state.news = payload;
    },
    [SET_NEWS_INBOUND_EMAILS](state, payload) {
      state.newsInboundEmails = payload;
    },
    // Surveys
    [SET_SURVEYS](state, payload) {
      state.surveys = payload;
    },
    [SET_GUARDBOOK_ENTRIES](state, payload) {
      state.guardbook.entries = payload;
    },
    [SET_GUARDBOOK_CATEGORIES](state, payload) {
      state.guardbook.entries = payload;
    },
  },

  actions: {
    // [RESET_MY_ORGANIZATION]({ commit }) {},

    // Surveys
    [GET_SURVEYS]({ commit }, payload) {
      const data = [];
      var options = {
        weekday: "short",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      var limit = 60;
      var queryDate = new Date();
      queryDate.setDate(queryDate.getDate() - 1);
      if (payload.paginationLimit != undefined) {
        limit = payload.paginationLimit;
      }

      var baseReference = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("surveys");
      // .where("deadline", ">=", fb.Timestamp.fromDate(new Date(queryDate)));
      var reference = baseReference;
      // if (payload.query.onlyOwn) {
      //   //queryIsFiltered is not available
      //   var ownUserGroup = payload.query.ownUserGroup; //rootState.organization.membership.group.id;
      //   var ownUid = fb.auth.currentUser.uid;

      //   reference = baseReference.where(
      //     "participants.allowedParticipantsIds",
      //     "array-contains-any",
      //     ["all", ownUid, ownUserGroup]
      //   );
      // }
      if (payload.query.onlyActive) {
        reference = reference.where(
          "deadline",
          ">=",
          fb.Timestamp.fromDate(new Date(queryDate))
        );
      }

      return reference
        .orderBy("deadline", "asc")
        .limit(limit)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const object = {
              ...doc.data(),
              // Overwrite
              deadlineDecoded: doc
                .data()
                .deadline.toDate()
                .toLocaleString("de-DE", options),
            };
            data.push(object);
          });
          commit(SET_SURVEYS, data);
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    [CREATE_SURVEY]({ commit }, payload) {
      commit(SET_LOADING, true);
      const docRef = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("surveys")
        .doc();
      docRef
        .set({
          title: payload.title,
          description: payload.description,
          deadline: payload.deadline,
          type: payload.type, // "external" or "in-app"
          participants: {
            allowedParticipantsIds: payload.participants.allowedParticipantsIds, // currently always "all"
            allowedPersons: payload.participants.allowedPersons, // currently always []
            allowedUserGroups: payload.participants.allowedUserGroups, // currently always [{ title: "Alle", id: "all"}]
          },
          external: {
            surveyUrl: payload.external.surveyUrl,
            resultsUrl: payload.external.resultsUrl,
          },
          organizationId: payload.organizationId,
          meta: {
            id: docRef.id,
            createdBy: fb.auth.currentUser.uid,
            createdAt: fb.FieldValue.serverTimestamp(),
          },
        })
        .then(function () {
          router.push({
            name: "my-organization-surveys",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },

    [UPDATE_SURVEY]({ commit }, payload) {
      commit(SET_LOADING, true);
      fb.organizationCollection
        .doc(payload.organizationId)
        .collection("surveys")
        .doc(payload.itemId)
        .update({
          title: payload.title,
          description: payload.description,
          deadline: payload.deadline,
          type: payload.type,
          external: {
            surveyUrl: payload.external.surveyUrl,
            resultsUrl: payload.external.resultsUrl,
          },
          "meta.lastUpdatedAt": fb.FieldValue.serverTimestamp(),
          "meta.lastUpdatedBy": fb.auth.currentUser.uid,
        })
        .then(function () {
          router.push({
            name: "my-organization-surveys",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },

    [DELETE_SURVEY]({ commit }, payload) {
      commit(SET_LOADING, true);
      const docRef = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("surveys")
        .doc(payload.itemId);
      docRef
        .delete()
        .then(function () {
          router.push({
            name: "my-organization-surveys",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },
    // News
    [GET_NEWS]({ commit }, payload) {
      const data = [];
      var baseReference = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("news")
        .where("status", "==", "published")
        .where(
          "publishedAt.timestamp",
          "<=",
          fb.Timestamp.fromDate(new Date())
        );
      var reference = baseReference;
      var limit = 60;
      if (payload.paginationLimit) {
        limit = payload.paginationLimit;
      }
      if (payload.query.onlyOwn) {
        //queryIsFiltered is not available
        var ownUserGroup = payload.query.ownUserGroup; //rootState.organization.membership.group.id;
        var ownUid = fb.auth.currentUser.uid;

        reference = baseReference.where(
          "config.recipients.allowedRecipientsIds",
          "array-contains-any",
          ["all", ownUid, ownUserGroup]
        );
      }

      reference
        .orderBy("publishedAt.timestamp", "desc")
        .limit(limit)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const object = {
              ...doc.data(),
              // Overwrite
            };
            data.push(object);
          });
          commit(SET_NEWS, data);
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },

    [CREATE_NEWS]({ commit }, payload) {
      commit(SET_LOADING, true);

      const callFunction = fb.functions.httpsCallable(
        "myOrganization-createNews"
      );
      callFunction({
        organizationId: payload.organizationId,

        news: {
          title: payload.title,
          priority: payload.priority,
          content: {
            html: payload.content.html,
          },
          attachments: [],
          contentType: "html",
          inputType: "editor",
          status: "published",
          publishedAt: {
            timestamp:
              payload.publishedAt.timestamp === "now"
                ? null
                : payload.publishedAt.timestamp,
          },
          publishedBy: {
            uid: fb.auth.currentUser.uid,
            displayName: fb.auth.currentUser.displayName,
          },
          recipients: {
            allowedRecipientsIds: payload.recipients.allowedRecipientsIds, // currently always "all"
            allowedPersons: payload.recipients.allowedPersons, // currently always []
            allowedUserGroups: payload.recipients.allowedUserGroups, // currently always [{ title: "Alle", id: "all"}]
          },
        },
      });

      commit(SET_LOADING, false);
    },

    [UPDATE_NEWS]({ commit }, payload) {
      commit(SET_LOADING, true);

      const callFunction = fb.functions.httpsCallable(
        "myOrganization-createNews"
      );
      return callFunction({
        organizationId: payload.organizationId,

        news: {
          id: payload.itemId,
          title: payload.title,
          priority: payload.priority,
          content: {
            html: payload.content.html,
          },
          attachments: [],
          contentType: "html", // "html" or "plain-text"
          inputType: "editor", // "external" or "in-app"
          status: "published", // "published" or "draft"
          publishedAt: {
            timestamp:
              payload.publishedAt.timestamp === "now"
                ? null
                : payload.publishedAt.timestamp,
          },
          publishedBy: {
            uid: fb.auth.currentUser.uid,
            displayName: fb.auth.currentUser.displayName,
          },
          recipients: {
            allowedRecipientsIds: payload.recipients.allowedRecipientsIds, // currently always "all"
            allowedPersons: payload.recipients.allowedPersons, // currently always []
            allowedUserGroups: payload.recipients.allowedUserGroups, // currently always [{ title: "Alle", id: "all"}]
          },
        },
      }).catch((error) => {
        throw error;
      });
    },

    [DELETE_NEWS]({ commit }, payload) {
      commit(SET_LOADING, true);
      const docRef = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("news")
        .doc(payload.itemId);
      docRef
        .delete()
        .then(function () {
          router.push({
            name: "my-organization-news",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },

    [GET_NEWS_INBOUND_EMAILS]({ commit }, payload) {
      const data = [];
      fb.organizationCollection
        .doc(payload.organizationId)
        .collection("inboundAdresses")
        .where("type", "==", "news")
        .where("uid", "==", fb.auth.currentUser.uid)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const object = {
              ...doc.data(),
              // Overwrite
            };
            data.push(object);
          });
          commit(SET_NEWS_INBOUND_EMAILS, data);
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    // Guardbook
    // Entries
    [GET_GUARDBOOK_ENTRIES]({ commit }, payload) {
      const data = [];
      fb.organizationCollection
        .doc(payload.organizationId)
        .collection("guardbook")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const object = {
              ...doc.data(),
              // Overwrite
            };
            data.push(object);
          });
          commit(SET_GUARDBOOK_ENTRIES, data);
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },

    [CREATE_GUARDBOOK_ENTRY]({ commit }, payload) {
      commit(SET_LOADING, true);
      const docRef = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("meine_organisation")
        .doc("teams")
        .collection("teams")
        .doc();
      docRef
        .set({
          organizationId: payload.organizationId,
          meta: {
            id: docRef.id,
            createdBy: fb.auth.currentUser.uid,
            createdAt: fb.FieldValue.serverTimestamp(),
          },
        })
        .then(function () {
          router.push({
            name: "my-organization-guardbook",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },

    [UPDATE_GUARDBOOK_ENTRY]({ commit }, payload) {
      commit(SET_LOADING, true);
      fb.organizationCollection
        .doc(payload.organizationId)
        .collection("guardbook")
        .doc(payload.itemId)
        .update({
          "meta.lastUpdatedAt": fb.FieldValue.serverTimestamp(),
          "meta.lastUpdatedBy": fb.auth.currentUser.uid,
        })
        .then(function () {
          router.push({
            name: "my-organization-guardbook",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },

    [DELETE_GUARDBOOK_ENTRY]({ commit }, payload) {
      commit(SET_LOADING, true);
      const docRef = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("guardbook")
        .doc(payload.itemId);
      docRef
        .delete()
        .then(function () {
          router.push({
            name: "my-organization-guardbook",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },

    // Categories
    [GET_GUARDBOOK_CATEGORIES]({ commit }, payload) {
      const data = [];
      fb.organizationCollection
        .doc(payload.organizationId)
        .collection("guardbook")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            data.push(doc.data());
          });
          commit(SET_GUARDBOOK_ENTRIES, data);
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },

    [CREATE_GUARDBOOK_CATEGORY]({ commit }, payload) {
      commit(SET_LOADING, true);
      const docRef = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("meine_organisation")
        .doc("teams")
        .collection("teams")
        .doc();
      docRef
        .set({
          organizationId: payload.organizationId,
          meta: {
            id: docRef.id,
            createdBy: fb.auth.currentUser.uid,
            createdAt: fb.FieldValue.serverTimestamp(),
          },
        })
        .then(function () {
          router.push({
            name: "my-organization-guardbook",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },

    [UPDATE_GUARDBOOK_CATEGORY]({ commit }, payload) {
      commit(SET_LOADING, true);
      fb.organizationCollection
        .doc(payload.organizationId)
        .collection("guardbook")
        .doc(payload.itemId)
        .update({
          "meta.lastUpdatedAt": fb.FieldValue.serverTimestamp(),
          "meta.lastUpdatedBy": fb.auth.currentUser.uid,
        })
        .then(function () {
          router.push({
            name: "my-organization-guardbook",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },

    [DELETE_GUARDBOOK_CATEGORY]({ commit }, payload) {
      commit(SET_LOADING, true);
      const docRef = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("guardbook")
        .doc(payload.itemId);
      docRef
        .delete()
        .then(function () {
          router.push({
            name: "my-organization-guardbook",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },
  },

  getters: {},
};
