<template>
  <div class="loading-overlay">
    <div class="text-center">
      <div v-if="overlay">
        <v-overlay :opacity="overlayOpacity" :color="overlayColor" z-index="4">
          <v-progress-circular
            indeterminate
            :color="progressColor"
            width="6"
            size="74"
          ></v-progress-circular>
        </v-overlay>
      </div>
      <!-- <div v-else>
        <v-progress-circular
          indeterminate
          width="6"
          :color="progressColor"
          size="64"
        ></v-progress-circular>
      </div> -->
    </div>
  </div>
</template>

<style></style>

<script>
export default {
  name: "loading-overlay",
  props: {
    overlay: {
      type: Boolean,
      default: false,
    },
    overlayColor: {
      type: String,
      default: function () {
        return this.$vuetify.theme.dark ? "#121212" : "#f6f7f9";
      },
    },
    overlayOpacity: {
      type: String,
      default: "1",
    },
    progressColor: {
      type: String,
      default: "red darken-1",
    },
  },
  data() {
    return {};
  },
  watch: {
    overlay(val) {
      val &&
        setTimeout(() => {
          this.overlay = false;
        }, 3000);
    },
  },
};
</script>
