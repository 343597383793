import {
  ADMINISTRATION_CONFIG_read,
  ADMINISTRATION_CONFIG_APPEARANCE_read,
  ADMINISTRATION_CONFIG_INHERITANCE_read,
  ADMINISTRATION_BILLING_read,
  ADMINISTRATION_USERS_ACCOUNTS_read,
  ADMINISTRATION_USERS_GROUPS_read,
  ADMINISTRATION_USERS_INVITES_create,
  ADMINISTRATION_USERS_INVITES_read,
  ADMINISTRATION_USERS_INVITES_update,
  ADMINISTRATION_USERS_GROUPS_create,
  ADMINISTRATION_USERS_GROUPS_update,
  ADMINISTRATION_SECURITY_ROLES_create,
  ADMINISTRATION_SECURITY_ROLES_read,
  ADMINISTRATION_SECURITY_ROLES_update,
  ADMINISTRATION_PRIVACY_read,
  ADMINISTRATION_PRIVACY_update,
  ADMINISTRATION_EXTENSIONS_read,
  ADMINISTRATION_EXTENSIONS_manage,
  ADMINISTRATION_AUDIT_read,
} from "@/data/permission-types.js";

export const admin =
  // admin
  {
    path: "admin",
    name: "admin",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../../views/admin/AdminIndex.vue"
      ),
    meta: {
      requiresAuth: true,
      requiresPermission: true,
    },
    children: [
      // admin > config
      {
        path: "config",
        name: "admin-config",
        component: () =>
          import(
            /* webpackChunkName: "admin-config" */ "../../views/admin/AdminConfig.vue"
          ),
        meta: {
          requiresAuth: true,
          requiresPermission: true,
          permissionID: `${ADMINISTRATION_CONFIG_read}`,
        },
        props: true,
        children: [
          {
            path: "general",
            name: "admin-config-tab-general",
            component: () =>
              import(
                /* webpackChunkName: "admin-config" */ "../../views/admin/config/ConfigTabGeneral.vue"
              ),
            meta: {
              requiresAuth: true,
              requiresPermission: true,
              permissionID: "",
            },
          },
          {
            path: "appearance",
            name: "admin-config-tab-appearance",
            component: () =>
              import(
                /* webpackChunkName: "admin-config" */ "../../views/admin/config/ConfigTabAppearance.vue"
              ),
            meta: {
              requiresAuth: true,
              requiresPermission: true,
              permissionID: `${ADMINISTRATION_CONFIG_APPEARANCE_read}`,
            },
          },
          {
            path: "inheritance",
            name: "admin-config-tab-inheritance",
            component: () =>
              import(
                /* webpackChunkName: "admin-config" */ "../../views/admin/config/ConfigTabInheritance.vue"
              ),
            meta: {
              requiresAuth: true,
              requiresPermission: true,
              permissionID: `${ADMINISTRATION_CONFIG_INHERITANCE_read}`,
            },
          },
        ],
      },

      // admin > billing
      {
        path: "billing",
        name: "admin-billing",
        component: () =>
          import(
            /* webpackChunkName: "admin-billing" */ "../../views/admin/AdminBilling.vue"
          ),
        meta: {
          requiresAuth: true,
          requiresPermission: true,
          permissionID: `${ADMINISTRATION_BILLING_read}`,
        },
      },
      // admin > Benutzer
      {
        path: "users",
        name: "admin-users",
        component: () =>
          import(
            /* webpackChunkName: "admin-accounts" */ "../../views/admin/AdminUsers.vue"
          ),
        meta: {
          requiresAuth: true,
          requiresPermission: true,
          permissionID: "",
        },
        children: [
          {
            path: "accounts",
            name: "admin-users-tab-accounts",
            component: () =>
              import(
                /* webpackChunkName: "admin-accounts" */ "../../views/admin/users/UsersTabAccounts.vue"
              ),
            meta: {
              requiresAuth: true,
              requiresPermission: true,
              permissionID: `${ADMINISTRATION_USERS_ACCOUNTS_read}`,
            },
          },
          {
            path: "groups",
            name: "admin-users-tab-groups",
            component: () =>
              import(
                /* webpackChunkName: "admin-accounts" */ "../../views/admin/users/UsersTabGroups.vue"
              ),
            meta: {
              requiresAuth: true,
              requiresPermission: true,
              permissionID: `${ADMINISTRATION_USERS_GROUPS_read}`,
            },
          },
        ],
      },
      {
        path: "users/accounts/:itemId",
        name: "admin-users-tab-accounts-account-details",
        component: () =>
          import(
            /* webpackChunkName: "admin-accounts" */ "../../views/admin/users/accounts/AccountDetails.vue"
          ),
        meta: {
          requiresAuth: true,
          requiresPermission: true,
          permissionID: `${ADMINISTRATION_USERS_ACCOUNTS_read}`,
        },
      },
      {
        path: "users/accounts/invites/new",
        name: "admin-users-tab-accounts-invite-new",
        component: () =>
          import(
            /* webpackChunkName: "admin-accounts" */ "../../views/admin/users/accounts/invites/InviteNew.vue"
          ),
        meta: {
          requiresAuth: true,
          requiresPermission: true,
          permissionID: `${ADMINISTRATION_USERS_INVITES_create}`,
        },
      },
      {
        path: "users/accounts/invites/:itemId",
        name: "admin-users-tab-accounts-invite-details",
        component: () =>
          import(
            /* webpackChunkName: "admin-accounts" */ "../../views/admin/users/accounts/invites/InviteDetails.vue"
          ),
        meta: {
          requiresAuth: true,
          requiresPermission: true,
          permissionID: `${ADMINISTRATION_USERS_INVITES_read}`,
        },
      },
      {
        path: "users/accounts/invites/:itemId/edit",
        name: "admin-users-tab-accounts-invite-edit",
        component: () =>
          import(
            /* webpackChunkName: "admin-accounts" */ "../../views/admin/users/accounts/invites/InviteEdit.vue"
          ),
        meta: {
          requiresAuth: true,
          requiresPermission: true,
          permissionID: `${ADMINISTRATION_USERS_INVITES_update}`,
        },
      },
      {
        path: "users/groups/new",
        name: "admin-users-tab-groups-group-new",
        component: () =>
          import(
            /* webpackChunkName: "admin-accounts" */ "../../views/admin/users/groups/GroupNew.vue"
          ),
        meta: {
          requiresAuth: true,
          requiresPermission: true,
          permissionID: `${ADMINISTRATION_USERS_GROUPS_create}`,
        },
      },
      {
        path: "users/groups/:itemId",
        name: "admin-users-tab-groups-group-details",
        component: () =>
          import(
            /* webpackChunkName: "admin-accounts" */ "../../views/admin/users/groups/GroupDetails.vue"
          ),
        meta: {
          requiresAuth: true,
          requiresPermission: true,
          permissionID: `${ADMINISTRATION_USERS_GROUPS_read}`,
        },
      },
      {
        path: "users/groups/:itemId/edit",
        name: "admin-users-tab-groups-group-edit",
        component: () =>
          import(
            /* webpackChunkName: "admin-accounts" */ "../../views/admin/users/groups/GroupEdit.vue"
          ),
        meta: {
          requiresAuth: true,
          requiresPermission: true,
          permissionID: `${ADMINISTRATION_USERS_GROUPS_update}`,
        },
      },
      // admin > security
      {
        path: "security",
        name: "admin-security",
        component: () =>
          import(
            /* webpackChunkName: "admin-security" */ "../../views/admin/AdminSecurity.vue"
          ),
        meta: {
          requiresAuth: true,
          requiresPermission: true,
          permissionID: "administration.sicherheit.view_Page",
        },
        props: true,
        children: [
          {
            path: "general",
            name: "admin-security-tab-general",
            component: () =>
              import(
                /* webpackChunkName: "admin-security" */ "../../views/admin/security/SecurityTabGeneral.vue"
              ),
            meta: {
              requiresAuth: true,
              requiresPermission: true,
              permissionID: "",
            },
          },
          {
            path: "roles",
            name: "admin-security-tab-roles",
            component: () =>
              import(
                /* webpackChunkName: "admin-security" */ "../../views/admin/security/SecurityTabRoles.vue"
              ),
            meta: {
              requiresAuth: true,
              requiresPermission: true,
              permissionID: `${ADMINISTRATION_SECURITY_ROLES_read}`,
            },
          },
          {
            path: "matrix",
            name: "admin-security-tab-matrix",
            component: () =>
              import(
                /* webpackChunkName: "admin-security" */ "../../views/admin/security/SecurityTabMatrix.vue"
              ),
            meta: {
              requiresAuth: true,
              requiresPermission: true,
              permissionID: `${ADMINISTRATION_SECURITY_ROLES_read}`,
            },
          },
          {
            path: "api",
            name: "admin-security-tab-api",
            component: () =>
              import(
                /* webpackChunkName: "admin-security" */ "../../views/admin/security/SecurityTabAPI.vue"
              ),
            meta: {
              requiresAuth: true,
              requiresPermission: true,
              permissionID: "",
            },
          },
        ],
      },
      {
        path: "security/roles/new",
        name: "admin-security-tab-roles-role-new",
        component: () =>
          import(
            /* webpackChunkName: "admin-security" */ "../../views/admin/security/roles/RoleNew.vue"
          ),
        meta: {
          requiresAuth: true,
          requiresPermission: true,
          permissionID: `${ADMINISTRATION_SECURITY_ROLES_create}`,
        },
      },
      {
        path: "security/roles/:itemId",
        name: "admin-security-tab-roles-role-details",
        component: () =>
          import(
            /* webpackChunkName: "admin-security" */ "../../views/admin/security/roles/RoleDetails.vue"
          ),
        meta: {
          requiresAuth: true,
          requiresPermission: true,
          permissionID: `${ADMINISTRATION_SECURITY_ROLES_read}`,
        },
      },
      {
        path: "security/roles/:itemId/edit",
        name: "admin-security-tab-roles-role-edit",
        component: () =>
          import(
            /* webpackChunkName: "admin-security" */ "../../views/admin/security/roles/RoleEdit.vue"
          ),
        meta: {
          requiresAuth: true,
          requiresPermission: true,
          permissionID: `${ADMINISTRATION_SECURITY_ROLES_update}`,
        },
      },
      // admin > privacy
      {
        path: "privacy",
        name: "admin-privacy",
        component: () =>
          import(
            /* webpackChunkName: "admin-privacy" */ "../../views/admin/AdminPrivacy.vue"
          ),
        meta: {
          requiresAuth: true,
          requiresPermission: true,
          permissionID: `${ADMINISTRATION_PRIVACY_read}`,
        },
        props: true,
        children: [
          {
            path: "general",
            name: "admin-privacy-tab-general",
            component: () =>
              import(
                /* webpackChunkName: "admin-privacy" */ "../../views/admin/privacy/PrivacyTabGeneral.vue"
              ),
            meta: {
              requiresAuth: true,
              requiresPermission: true,
              permissionID: `${ADMINISTRATION_PRIVACY_read}`,
            },
          },
          {
            path: "policy",
            name: "admin-privacy-tab-policy",
            component: () =>
              import(
                /* webpackChunkName: "admin-privacy" */ "../../views/admin/privacy/PrivacyTabPolicy.vue"
              ),
            meta: {
              requiresAuth: true,
              requiresPermission: true,
              permissionID: `${ADMINISTRATION_PRIVACY_read}`,
            },
          },
        ],
      },
      {
        path: "privacy/policy/new",
        name: "admin-privacy-tab-policy-policy-new",
        component: () =>
          import(
            /* webpackChunkName: "admin-privacy" */ "../../views/admin/privacy/policy/PrivacyPolicyNew.vue"
          ),
        meta: {
          requiresAuth: true,
          requiresPermission: true,
          permissionID: `${ADMINISTRATION_PRIVACY_update}`,
        },
      },
      // admin > Extensions
      {
        path: "extensions",
        name: "admin-extensions",
        component: () =>
          import(
            /* webpackChunkName: "admin-extensions" */ "../../views/admin/AdminExtensions.vue"
          ),
        meta: {
          requiresAuth: true,
          requiresPermission: true,
          permissionID: `${ADMINISTRATION_EXTENSIONS_read}`,
        },
      },
      {
        path: "extensions/shop",
        name: "admin-extensions-shop",
        component: () =>
          import(
            /* webpackChunkName: "admin-extensions" */ "../../views/admin/extensions/ExtensionsShop.vue"
          ),
        meta: {
          requiresAuth: true,
          requiresPermission: true,
          permissionID: `${ADMINISTRATION_EXTENSIONS_manage}`,
        },
      },
      // admin > audit
      {
        path: "audit",
        name: "admin-audit",
        component: () =>
          import(
            /* webpackChunkName: "admin-audit" */ "../../views/admin/AdminAudit.vue"
          ),
        meta: {
          requiresAuth: true,
          requiresPermission: true,
          permissionID: `${ADMINISTRATION_AUDIT_read}`,
        },
      },
    ],
  };
