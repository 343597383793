<template>
  <!-- navigation -->

  <div id="main-wrapper">
    <div id="nav">
      <app-bar
        :showDrawerToggle="showNavigationDrawer"
        @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
      ></app-bar>
      <navigation-drawer
        :v-if="showNavigationDrawer"
        ref="drawer"
      ></navigation-drawer>
    </div>

    <!-- main -->

    <div id="main">
      <help-widget></help-widget>
      <v-main>
        <Loading v-if="loading" overlay="true"></Loading>
        <!-- content -->
        <router-view id="content"></router-view>
      </v-main>
    </div>

    <!-- footer -->

    <div id="footer">
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
// Import of components
import { mapState } from "vuex";
import AppBar from "@/components/_system/main/AppBar.vue";
import NavigationDrawer from "@/components/_system/main/NavigationDrawer.vue";
import HelpWidget from "@/components/_system/main/HelpWidget.vue";
import Loading from "@/components/_system/helpers/Loading.vue";
import Footer from "@/components/_system/main/Footer.vue";

export default {
  name: "content-layout-with-drawer",

  // Registers components to prepare for use
  components: {
    AppBar,
    NavigationDrawer,
    HelpWidget,
    Loading,
    Footer,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(["user"]),
    showNavigation() {
      //return Object.keys(this.user).length > 1
      return true;
    },
    showNavigationDrawer() {
      if (this.$route.meta.layout === "content-without-drawer") {
        return false;
      } else {
        return true;
      }
      // let disabledOnPages = [
      //   "setup-onboarding",
      //   "organization-list",
      //   "organization-new",
      //   "notification-center",
      //   "profile",
      //   "profile-tab-general",
      //   "profile-tab-settings",
      //   "profile-tab-privacy",
      //   "organization-join",
      //   "not-found",
      //   "error",
      // ];
      // if (disabledOnPages.indexOf(this.$route.name) == -1) {
      //   return true;
      // } else {
      //   return false;
      // }
    },
    loading() {
      return false;
    },
    loading1() {
      if (["organization"].indexOf(this.$route.name) == -1) {
        return false;
      } else {
        // var vuex = this.$store.state.loading
        var currentOrg = this.$store.state.organization.activeOrganization;
        var membership = this.$store.state.organisation.membership;
        if (currentOrg == null || membership == null) {
          return true;
        } else {
          return false;
        }
      }
      // this.$store.state.loading;
    },
  },
  watch: {
    $route: "setLanguageOfVuetify",
  },
  created() {
    this.setLanguageOfVuetify();
  },
  methods: {
    setLanguageOfVuetify() {
      this.$vuetify.lang.current = this.$i18n.locale;
    },
  },
};
</script>
<style>
/* #content {
   height: 100vh !important; 
}
#footer {
  bottom: 0px !important;
} */

html,
/* body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#main-wrapper {
  box-sizing: border-box;
  min-height: 100%;
  padding: 0 0 100px;
  position: relative;
} */

#footer {
  bottom: 0;
  height: 100px;
  left: 0;
  position: absolute;
  width: 100%;
}
</style>
