<template>
  <v-app-bar
    app
    :clipped-left="$vuetify.breakpoint.lgAndUp"
    :clipped-right="$vuetify.breakpoint.lgAndUp"
    elevate-on-scroll
    fixed
    :color="$vuetify.theme.dark ? '' : 'white'"
    style="border-bottom: 4px solid #e53935 !important"
  >
    <v-app-bar-nav-icon
      v-if="showDrawerToggle"
      @click.stop="$emit('toggle-drawer')"
      class="mr-2"
    ></v-app-bar-nav-icon>
    <v-img
      src="@/assets/images/navLogo.png"
      max-height="35"
      max-width="35"
      contain
    ></v-img>

    <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
      <span class="title">Mira One</span>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-tooltip bottom v-if="showBackToOrgBtn">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
          exact
          :to="{ name: 'organization-list' }"
        >
          <v-icon>mdi-office-building</v-icon>
        </v-btn>
      </template>
      <span>Organisationsübersicht</span>
    </v-tooltip>
    <!-- <app-bar-organization-switcher></app-bar-organization-switcher> -->
    <!-- <app-bar-notification-center></app-bar-notification-center> -->
    <app-bar-user-menu></app-bar-user-menu>
  </v-app-bar>
</template>

<script>
//import AppBarOrganizationSwitcher from "@/components/app-bar/AppBarOrganizationSwitcher.vue";
// import AppBarNotificationCenter from "@/components/app-bar/AppBarNotificationCenter.vue";
import AppBarUserMenu from "@/components/app-bar/AppBarUserMenu.vue";
export default {
  name: "app-bar",
  components: {
    // AppBarOrganizationSwitcher,
    // AppBarNotificationCenter,
    AppBarUserMenu,
  },
  props: {
    showDrawerToggle: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    showBackToOrgBtn() {
      let disabledOnPages = [
        "setup-onboarding",
        "organization-list",
        "organization-new",
        "organization-join",
      ];
      if (disabledOnPages.indexOf(this.$route.name) == -1) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {},
};
</script>
