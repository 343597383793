import Vue from "vue";
import Vuetify from "vuetify/lib";
import "@mdi/font/css/materialdesignicons.css";
// import colors from "vuetify/lib/util/colors";
// Locale
import de from "vuetify/es5/locale/de";
import i18n from "./i18n";

import preset from "../presets/preset";

Vue.use(Vuetify, {
  iconfont: "md",
});

export default new Vuetify({
  preset,
  lang: {
    locales: { de },
    current: i18n.locale,
  },
  options: {
    customProperties: true,
    variations: false,
  },
  theme: {
    dark: false,
    themes: {
      light: {
        //   primary: colors.red.darken1,
        //   secondary: "#1867c0",
        //   accent: "#82B1FF",
        //   error: "#FF5252",
        //   info: "#2196F3",
        //   success: "#4CAF50",
        // warning: "#FFC107",
        background: "#f6f7f9",
      },
      dark: {
        //   primary: colors.red.darken1,
        //   secondary: "#1867c0",
        //   accent: "#82B1FF",
        //   error: "#FF5252",
        //   info: "#2196F3",
        //   success: "#4CAF50",
        //   warning: "#FFC107",
        background: "#121212",
      },
    },
  },
});
