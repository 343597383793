<template>
  <div>
    <v-btn
      color="rgb(0, 96, 99)"
      dark
      elevation="4"
      fixed
      bottom
      right
      class="mb-2"
      @click="openFreshdesk()"
      style="z-index: 200"
    >
      <v-icon left>mdi-help-circle-outline</v-icon>Hilfe
    </v-btn>
  </div>
</template>

<script>
import { auth } from "@/firebase";
export default {
  name: "help-widget",
  methods: {
    loadFreshdesk() {
      let script = document.createElement("script");
      script.src = "https://euc-widget.freshworks.com/widgets/80000002320.js";
      script.async = true;
      script.defer = true;
      script.onload = this.initFreshdesk;
      document.body.appendChild(script);
    },
    initFreshdesk() {
      window.fwSettings = {
        widget_id: 80000002320,
        locale: "de",
      };
      if (!window.FreshworksWidget) {
        var n = function () {
          n.q.push(arguments);
        };
        n.q = [];
        window.FreshworksWidget = n;
      }
      window.FreshworksWidget("open");
    },
    openFreshdesk() {
      if (!window.FreshworksWidget) {
        this.initFreshdesk();
        this.loadFreshdesk();
        window.FreshworksWidget("hide", "launcher");
        window.FreshworksWidget("open");
        this.prefillFreshdesk();
      } else {
        window.FreshworksWidget("open");
      }
    },
    prefillFreshdesk() {
      const name = auth.currentUser.displayName || "";
      const email = auth.currentUser.email || "";
      const organizationId = this.$route.params.organizationId || "";
      window.FreshworksWidget("prefill", "ticketForm", {
        name: name,
        email: email,
        custom_fields: {
          cf_organization_id: organizationId,
        },
        priority: 1,
        status: 2,
      });
    },
  },
};
</script>
