export const stats = {
  path: "stats",
  name: "stats",
  component: () =>
    import(
      /* webpackChunkName: "errors" */ "../../views/_system/errors/ModuleNotActivated.vue"
    ),
  meta: {
    requiresAuth: true,
    requiresPermission: true,
    permissionID: "",
  },
};
