import Vue from "vue";
import Vuex from "vuex";

import user from "./modules/user";
import organization from "./modules/organization";
import myOrganization from "./modules/my-organization";
import scheduling from "./modules/scheduling";
import dutyManagement from "./modules/duty-management";
import availability from "./modules/availability";
import memberManagement from "./modules/member-management";
import accounting from "./modules/accounting";
import finance from "./modules/finance";
import admin from "./modules/admin";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: true,
  },

  modules: {
    user,
    organization,
    myOrganization,
    scheduling,
    dutyManagement,
    availability,
    memberManagement,
    accounting,
    finance,
    admin,
  },
});
