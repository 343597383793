// Available modules via Vuex

export const USER = "user/";
export const ORGANISATION = "organization/";

export const MY_ORGANIZATION = "myOrganization/";
export const SCHEDULING = "scheduling/";
export const DUTY_MANAGEMENT = "dutyManagement/";
export const AVAILABILITY = "availability/";
export const MEMBER_MANAGEMENT = "memberManagement/";
export const ACCOUNTING = "accounting/";
export const FINANCE = "finance/";
export const ADMIN = "admin/";
