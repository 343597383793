export const finance = {
  path: "finance",
  name: "finance",
  component: () =>
    import(
      /* webpackChunkName: "finance" */ "../../views/finance/FinanceIndex.vue"
    ),
  meta: {
    requiresAuth: true,
    requiresPermission: true,
  },
  children: [
    // finance > bookkeeping
    {
      path: "bookkeeping",
      name: "finance-bookkeeping",
      component: () =>
        import(
          /* webpackChunkName: "finance-bookkeeping" */ "../../views/finance/FinanceBookkeeping.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        // permissionID: `${finance_expenses_read}`,
      },
    },
    // finance > expenses
    {
      path: "expenses",
      name: "finance-expenses",
      component: () =>
        import(
          /* webpackChunkName: "finance-expenses" */ "../../views/finance/FinanceExpenses.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        // permissionID: `${finance_expenses_read}`,
      },
    },
    {
      path: "expenses/new",
      name: "finance-expenses-new",
      component: () =>
        import(
          /* webpackChunkName: "finance-expenses-manage" */ "../../views/finance/expenses/ExpenseNew.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        // permissionID: `${finance_expenses_create}`,
      },
    },
    {
      path: "expenses/:itemId/edit",
      name: "finance-expenses-edit",
      component: () =>
        import(
          /* webpackChunkName: "finance-expenses-manage" */ "../../views/finance/expenses/ExpenseEdit.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        // permissionID: `${finance_expenses_update}`,
      },
    },
    {
      path: "expenses/:itemId",
      name: "finance-expenses-details",
      component: () =>
        import(
          /* webpackChunkName: "finance-expenses-manage" */ "../../views/finance/expenses/ExpenseDetails.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        // permissionID: `${finance_REQUESTS_read}`,
      },
    },
    // finance > reports
    {
      path: "reports",
      name: "finance-reports",
      component: () =>
        import(
          /* webpackChunkName: "finance-reports" */ "../../views/finance/FinanceReports.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        // permissionID: `${finance_expenses_read}`,
      },
    },
    // finance > config
    {
      path: "config",
      name: "finance-config",
      component: () =>
        import(
          /* webpackChunkName: "finance-config" */ "../../views/finance/FinanceConfig.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        // permissionID: `${finance_expenses_read}`,
      },
    },
  ],
};
