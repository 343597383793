import {
  AVAILABILITY_LEAVES_read,
  AVAILABILITY_LEAVES_create,
  // AVAILABILITY_LEAVES_read,
  AVAILABILITY_LEAVES_update,
  AVAILABILITY_FORECASTS_read,
  AVAILABILITY_ARCHIVE_read,
  // AVAILABILITY_CONFIG_read,
  AVAILABILITY_CONFIG_GENERAL_read,
} from "@/data/permission-types.js";

export const availability = {
  path: "availability",
  name: "availability",
  component: () =>
    import(
      /* webpackChunkName: "availability" */ "../../views/availability/AvailabilityIndex.vue"
    ),
  meta: {
    requiresAuth: true,
    requiresPermission: true,
  },
  children: [
    // availability > leaves
    {
      path: "leaves",
      name: "availability-leaves",
      component: () =>
        import(
          /* webpackChunkName: "availability-leaves" */ "../../views/availability/AvailabilityLeaves.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${AVAILABILITY_LEAVES_read}`,
      },
    },
    {
      path: "leaves/new",
      name: "availability-leaves-new",
      component: () =>
        import(
          /* webpackChunkName: "availability-leaves-manage" */ "../../views/availability/leaves/LeaveNew.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${AVAILABILITY_LEAVES_create}`,
      },
    },
    {
      path: "leaves/:itemId/edit",
      name: "availability-leaves-edit",
      component: () =>
        import(
          /* webpackChunkName: "availability-leaves-manage" */ "../../views/availability/leaves/LeaveEdit.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${AVAILABILITY_LEAVES_update}`,
      },
    },
    {
      path: "leaves/:itemId",
      name: "availability-leaves-details",
      component: () =>
        import(
          /* webpackChunkName: "availability-leaves-manage" */ "../../views/availability/leaves/LeaveDetails.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${AVAILABILITY_LEAVES_read}`,
      },
    },

    // availability > units
    {
      path: "units",
      name: "availability-units",
      component: () =>
        import(
          /* webpackChunkName: "availability-units" */ "../../views/availability/AvailabilityUnits.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: "",
      },
      children: [
        {
          path: "units",
          name: "availability-units-tab-units",
          component: () =>
            import(
              /* webpackChunkName: "availability-units" */ "../../views/availability/units/UnitsTabUnits.vue"
            ),
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionID: "",
          },
        },
        {
          path: "positions",
          name: "availability-units-tab-positions",
          component: () =>
            import(
              /* webpackChunkName: "availability-units" */ "../../views/availability/units/UnitsTabPositions.vue"
            ),
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionID: "",
          },
        },
      ],
    },
    {
      path: "units/units/new",
      name: "availability-units-tab-units-unit-new",
      component: () =>
        import(
          /* webpackChunkName: "availability-units" */ "../../views/availability/units/units/UnitNew.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: "",
      },
    },
    {
      path: "units/units/:itemId",
      name: "availability-units-tab-units-unit-details",
      component: () =>
        import(
          /* webpackChunkName: "availability-units" */ "../../views/availability/units/units/UnitDetails.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: "",
      },
    },
    {
      path: "units/:itemId/edit",
      name: "availability-units-tab-units-unit-edit",
      component: () =>
        import(
          /* webpackChunkName: "availability-units" */ "../../views/availability/units/units/UnitEdit.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: "",
      },
    },
    {
      path: "units/positions/new",
      name: "availability-units-tab-positions-position-new",
      component: () =>
        import(
          /* webpackChunkName: "availability-units" */ "../../views/availability/units/positions/PositionNew.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: "",
      },
    },
    {
      path: "units/positions/:itemId",
      name: "availability-units-tab-positions-position-details",
      component: () =>
        import(
          /* webpackChunkName: "availability-units" */ "../../views/availability/units/positions/PositionDetails.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: "",
      },
    },
    {
      path: "units/positions/:itemId/edit",
      name: "availability-units-tab-positions-position-edit",
      component: () =>
        import(
          /* webpackChunkName: "availability-units" */ "../../views/availability/units/positions/PositionEdit.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: "",
      },
    },

    // availability > forecasts
    {
      path: "forecasts",
      name: "availability-forecasts",
      component: () =>
        import(
          /* webpackChunkName: "availability-forecasts" */ "../../views/availability/AvailabilityForecasts.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${AVAILABILITY_FORECASTS_read}`,
      },
      children: [
        {
          path: "overview",
          name: "availability-forecasts-tab-overview",
          component: () =>
            import(
              /* webpackChunkName: "availability-forecasts" */ "../../views/availability/forecasts/ForecastsOverview.vue"
            ),
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionID: "",
          },
        },
        {
          path: "planner",
          name: "availability-forecasts-tab-planner",
          component: () =>
            import(
              /* webpackChunkName: "availability-forecasts" */ "../../views/availability/forecasts/ForecastsPlanner.vue"
            ),
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionID: "",
          },
        },
      ],
    },
    // availability > config
    {
      path: "config",
      name: "availability-config",
      component: () =>
        import(
          /* webpackChunkName: "availability-config" */ "../../views/availability/AvailabilityConfig.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: "",
        // permissionID: `${AVAILABILITY_CONFIG_read}`,
      },
      children: [
        {
          path: "general",
          name: "availability-config-tab-general",
          component: () =>
            import(
              /* webpackChunkName: "availability-config" */ "../../views/availability/config/ConfigTabGeneral.vue"
            ),
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionID: `${AVAILABILITY_CONFIG_GENERAL_read}`,
          },
        },
      ],
    },
    {
      path: "archive",
      name: "availability-archive",
      component: () =>
        import(
          /* webpackChunkName: "availability-archive" */ "../../views/availability/AvailabilityArchive.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${AVAILABILITY_ARCHIVE_read}`,
      },
      children: [
        {
          path: "leaves",
          name: "availability-archive-tab-leaves",
          component: () =>
            import(
              /* webpackChunkName: "availability-archive" */ "../../views/availability/archive/ArchiveTabLeaves.vue"
            ),
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionID: `${AVAILABILITY_ARCHIVE_read}`,
          },
        },
        // {
        //   path: "requests",
        //   name: "availability-archive-tab-requests",
        //   component: () =>
        //     import(
        //       /* webpackChunkName: "availability-archive" */ "../../views/availability/archive/ArchiveTabRequests.vue"
        //     ),
        //   meta: {
        //     requiresAuth: true,
        //     requiresPermission: true,
        //     permissionID: `${AVAILABILITY_ARCHIVE_read}`,
        //   },
        // },
      ],
    },
  ],
};
