<template>
  <div>
    <v-main>
      <router-view></router-view>
    </v-main>
    <div id="footer">
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import Footer from "@/components/_system/main/Footer.vue";
export default {
  name: "blank-layout",
  components: {
    Footer,
  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
};
</script>
