import Vue from "vue";
import VueRouter from "vue-router";
import { auth } from "../firebase";
import { routes } from "./router";
import i18n from "../plugins/i18n";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes,
});
// navigation guard to check for logged in users
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
  let language = to.params.locale;

  if (!i18n.availableLocales.includes(language)) {
    language = process.env.VUE_APP_I18N_LOCALE || "de";
    i18n.locale = language;
    return next(language);
  }
  if (i18n.locale !== language) {
    i18n.locale = language;
  }

  if (requiresAuth && !auth.currentUser) {
    return next({
      path: `/${i18n.locale}/login`,
      query: { redirect: to.fullPath },
    });
  } else {
    next();
  }
});

export default router;
