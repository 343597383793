import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/functions";
// import "firebase/compat/app-check";

let firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
};

firebase.initializeApp(firebaseConfig);

// Firebase Cloud Storage
var db = firebase.firestore();
// db.enablePersistence().catch(function(err) {
//   if (err.code == "failed-precondition") {
//     // Multiple tabs open, persistence can only be enabled
//     // in one tab at a a time.
//     // ...
//   } else if (err.code == "unimplemented") {
//     // The current browser does not support all of the
//     // features required to enable persistence
//     // ...
//   }
// });
// Subsequent queries will use persistence, if it was enabled successfully

// Firebase Cloud Storage
var storage = firebase.storage();

// Firebase Authentication
const auth = firebase.auth();
const currentUser = firebase.auth().currentUser;

// Firebase Cloud Functions
const functions = firebase.app().functions("europe-west3");

// Firebase App Check
// if (location.hostname === "localhost") {
//   console.info(`localhost detected – ${process.env.NODE_ENV}`);
//   if (process.env.NODE_ENV !== "production") {
//     self.FIREBASE_APPCHECK_DEBUG_TOKEN =
//       process.env.VUE_APP_FIREBASE_APP_CHECK_DEBUG_TOKEN;
//   }
// }
// const appCheck = firebase.appCheck();
// appCheck.activate(
//   new firebase.appCheck.ReCaptchaEnterpriseProvider(
//     process.env.VUE_APP_FIREBASE_APP_CHECK_TOKEN
//   ),
//   true
// );

// Firebase Emulator
// if (location.hostname === "localhost") {
//   auth.useEmulator("http://localhost:9099/");
//   db.useEmulator("localhost", 8082);
//   storage.useEmulator("localhost", 9199);
//   functions.useEmulator("localhost", 5001);
// }

// Firebase Helpers
const TaskEvent = firebase.storage.TaskEvent;
const FieldValue = firebase.firestore.FieldValue;
const Timestamp = firebase.firestore.Timestamp;
const GeoPoint = firebase.firestore.GeoPoint;
const EmailAuthProvider = firebase.auth.EmailAuthProvider;

// CollectionReferences
const usersCollection = db.collection("users");
const organizationCollection = db.collection("organizations");

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
const db1 = getFirestore(app);
// export utils/refs
export {
  // Utils
  db,
  db1,
  auth,
  currentUser,
  functions,
  storage,
  // Helpers
  TaskEvent,
  FieldValue,
  Timestamp,
  GeoPoint,
  EmailAuthProvider,
  // References
  usersCollection,
  organizationCollection,
};
