import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./plugins/i18n";
import { auth } from "./firebase";
import { USER } from "@/store/modules.js";
import {
  SET_USER,
  SET_USER_IS_AUTHENTICATED,
  SET_USER_AUTH_CHECK_STATUS,
} from "./store/mutation-types";
import { GET_USER_PROFILE } from "./store/action-types";

Vue.config.productionTip = false;

let app;
auth.onAuthStateChanged((user) => {
  // Initialization of Vue after Firebase
  if (!app) {
    // app = new Vue({
    //   data: {},
    //   router,
    //   vuetify,
    //   store,
    //   i18n,
    //   render: (h) => h(App),
    // }).$mount("#app");
  }

  auths(user).then(function () {
    store.commit(`${USER}${SET_USER_AUTH_CHECK_STATUS}`, false);
  });
});

function auths(user) {
  if (user) {
    store.commit(`${USER}${SET_USER_IS_AUTHENTICATED}`, user ? true : false);
    store.commit(`${USER}${SET_USER}`, user ? true : {});
    store.dispatch(`${USER}${GET_USER_PROFILE}`, {
      uid: user.uid,
      allowRedirect: true,
    });
  }
  return Promise.resolve(true);
}

new Vue({
  data: {},
  router,
  vuetify,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
